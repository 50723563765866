import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../styles/components/button.emotion";
import { FooterContact } from "../styles/components/footer-contact.emotion";
import { Error, FormControl, Label } from "../styles/utils/form.emotion";
import { Container, Wrapper } from "../styles/utils/global.emotion";
import { Description, SubTitle } from "../styles/utils/typograth.emotion";
import VMasker from "vanilla-masker";
import { useAlert } from "../context/alert.context";
import { Message } from "../models/message.model";
import api from "../service/api";
import { useGlobal } from "../context/global.context";
import { event } from "../lib/gtag";

interface Form {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export default function FooterContactComponent() {
  const { presentAlert } = useAlert();
  const { generalRegister } = useGlobal();

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const [isSubmitting, setSubmitting] = React.useState(false);
  const onSubmit: SubmitHandler<Form> = async (fields) => {
    setSubmitting(true);

    const data = {
      ...new Message(),
      subject: "Contato - formulário do rodapé",
      name: fields?.name,
      email: fields?.email,
      phone: fields?.phone,
      message: fields?.message,
    } as Message;

    const response = await api.post("message", data);

    if (response?.status === 200) {
      presentAlert({
        title: "Sucesso!",
        message:
          "Nós analisaremos sua mensagem e responderemos o mais breve possível.",
        buttonText: "Certo!",
      });

      event({
        action: "form_home_complet_submitted",
        category: "generate_lead",
        label: "Formúlario Home Rodapé",
        value: JSON.stringify(data),
      });

      reset({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }

    setSubmitting(false);
  };

  return (
    <FooterContact>
      <Container size="lg">
        <div className="box">
          <Wrapper
            flexDirection="row"
            alignItems="center"
            gap="max(2rem, 2.6vw)"
            mobileOptions={{ flexDirection: "column" }}
          >
            <div className="col">
              <SubTitle>
                {generalRegister?.contactTitle || "Entre em contato"}
              </SubTitle>
              {generalRegister?.contactDescription && (
                <Description>{generalRegister?.contactDescription}</Description>
              )}
            </div>
            <div className="col">
              <form id="form-contact" onSubmit={handleSubmit(onSubmit)}>
                <Wrapper
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <FormControl size={100}>
                    <Label>Nome</Label>
                    <input
                      id="form-contact-name"
                      {...register("name", {
                        required: "Este campo é requirido",
                      })}
                      placeholder="Digite seu nome"
                    />
                    {errors?.name ? (
                      <Error>
                        <i className="fa-solid fa-info-circle"></i>
                        <span>{errors?.name?.message}</span>
                      </Error>
                    ) : null}
                  </FormControl>
                  <FormControl size={100}>
                    <Label>Email</Label>
                    <input
                      id="form-contact-email"
                      {...register("email", {
                        required: "Este campo é requirido",
                      })}
                      type="email"
                      placeholder="Digite seu e-mail"
                    />
                    {errors?.email ? (
                      <Error>
                        <i className="fa-solid fa-info-circle"></i>
                        <span>{errors?.email?.message}</span>
                      </Error>
                    ) : null}
                  </FormControl>
                  <FormControl size={100}>
                    <Label>Telefone</Label>
                    <input
                      id="form-contact-phone"
                      {...register("phone", {
                        required: "Este campo é requirido",
                      })}
                      type="tel"
                      placeholder="Digite seu telefone"
                      onChange={(ev) => {
                        const phoneFormatted = VMasker.toPattern(
                          ev.target.value,
                          ev.target.value.length > 14
                            ? "(99) 99999-9999"
                            : "(99) 9999-9999"
                        );
                        setValue("phone", phoneFormatted);
                      }}
                    />
                    {errors?.phone ? (
                      <Error>
                        <i className="fa-solid fa-info-circle"></i>
                        <span>{errors?.phone?.message}</span>
                      </Error>
                    ) : null}
                  </FormControl>
                  <FormControl isTextArea>
                    <Label>Mensagem</Label>
                    <textarea
                      id="form-contact-message"
                      {...register("message", {
                        required: "Este campo é requirido",
                      })}
                      placeholder="Digite uma mensagem"
                      rows={8}
                    ></textarea>
                    {errors?.message ? (
                      <Error>
                        <i className="fa-solid fa-info-circle"></i>
                        <span>{errors?.message?.message}</span>
                      </Error>
                    ) : null}
                  </FormControl>
                  <Button buttonStyle="default" type="submit">
                    <span>ENVIAR</span>
                  </Button>
                </Wrapper>
              </form>
            </div>
          </Wrapper>
        </div>
      </Container>
    </FooterContact>
  );
}
