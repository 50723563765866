import styled from "@emotion/styled";
import { useMediaQuery } from "../utils/responsive.emotion";

export const FooterContact = styled.div`
  margin-bottom: -70px;
  position: relative;
  z-index: 2;

  .box {
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 10px 30px rgb(0 0 0 / 16%);
    padding: 2.6vw 5.4vw;
    background-color: ${(props) => props.theme.colors.gray_light};

    .col {
      h2 {
        margin-bottom: 1.25rem;
      }
    }
  }
  ${() => useMediaQuery("sm", "max")} {
    .box {
      padding: 4rem 2rem;
    }
  }
`;
