import styled from "@emotion/styled";

export const ProductItem = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  .image {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray};
      z-index: 10;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    img {
      object-fit: contain;
      transition: var(--transition-base);
      transition: all 300ms;

      &:nth-of-type(2) {
        opacity: 0;
        object-fit: cover;
      }
    }
  }

  h2 {
    font-size: 1.25em;
    font-weight: 700;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
  }

  .content {
    text-align: center;
    h3 {
      color: ${(props) => props.theme.colors.green};
      font-weight: 700;
      --min: 0.9em;
      --val: 1vw;
      --max: 1.125em;

      small {
        display: block;
        text-decoration: line-through;
        font-size: 0.7em;
        font-weight: 300;
        color: ${(props) => props.theme.colors.gray_dark};
      }
    }

    span {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      color: ${(props) => props.theme.colors.gray_dark};
      text-transform: uppercase;
    }
  }

  &:hover {
    cursor: pointer;

    .image {
      img {
        &:first-of-type {
          opacity: 0;
        }

        &:last-of-type {
          transform: scale(1.125);
          opacity: 1;
        }
      }
    }

    .content {
      .button {
        background-color: ${(props) => props.theme.colors.green};
        border-color: ${(props) => props.theme.colors.green};

        span {
          color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
`;
