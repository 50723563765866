import Image from "next/image";
import Link from "next/link";
import { Product } from "../../models/product.model";
import { moneyMask } from "../../shared/money-mask.function";
import { ProductItem } from "../../styles/components/product/product-item.emotion";
import { useState } from "react";

interface Props {
  item?: Product;
}

export default function ProductItemComponent({ item }: Props) {
  const promotionalValue = Number(item?.promotionalValue);
  const value = Number(item?.value);

  const total = promotionalValue > 0 ? promotionalValue : value;

  const variation = item?.variations?.[0];

  const imageSrc =
    variation?.image || variation?.images?.[0]?.file || "/logo.png";

  const ambientImageSrc = item?.ambientImage || "/about-banner.png";

  const [isLoading, setLoading] = useState(true);

  return (
    <Link href="/produto/[slug]" as={`/produto/${variation?.slug}`}>
      <ProductItem>
        <div className="image">
          {isLoading && <div className="image-overlay"></div>}
          <Image
            src={imageSrc}
            alt={item?.name?.toString() || "Produto"}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
            onLoadingComplete={() => setLoading(false)}
            loading="lazy"
          />
          {item?.ambientImage && (
            <Image
              src={ambientImageSrc}
              alt={`${item?.name} - Ambientada`}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
        </div>
        <h2>{item?.name}</h2>
        <div className="content">
          {item?.value && (
            <h3>
              {promotionalValue > 0 ? <small>{moneyMask(value)}</small> : null}
              {moneyMask(total)}
            </h3>
          )}
          {item?.formOfPayment ? <span>{item?.formOfPayment}</span> : null}
        </div>
      </ProductItem>
    </Link>
  );
}
