interface IOptions {
  withDecimals?: boolean;
  coin?: boolean;
  fractionDigits?: number;
}

/**
 * use this function to mask money values
 * @param text - the test to be masked
 * @return the masked value
 */
export function moneyMask(
  input: string | number,
  { withDecimals, coin = true, fractionDigits = 2 }: IOptions = {}
): string {
  if (!input || input === "") {
    return input?.toLocaleString();
  }
  const number =
    typeof input === "string"
      ? parseInt(input.replace(/[^0-9]/g, "")) || 0 // if it is a string, remove all characteres and convert to number
      : input;
  const withDecimal = withDecimals ? number / 100 : number;
  return `${coin ? "R$ " : ""}${withDecimal.toLocaleString("pt-BR", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })}`;
}

/**
 * use this function to unmask money values
 * @param text - the masked value to be unmasked
 * @return the unmasked value
 */
export function moneyUnmask(text: string): number {
  return Number(text.replace(/[^0-9,]+/g, "").replace(",", ".")) ?? 0;
}

/**
 * use this function to check if is a valid money
 * @param text - the value to be tested
 * @return true if the money value is valid
 */
export function moneyCheckMask(text: string): boolean {
  const regMasked = /^\d+((\.\d{3})+)?(,\d{2})?$/;
  return regMasked.test(text);
}

export const moneyErrorMessage = "Formato esperado: 0.000,00";
